import * as React from 'react'
import { CDN_ENDPOINT } from '@env'
import { BurgerMenuContainer } from './style'
import { Link, withRouterContext } from '@sejinmind/piip-utils'

import { slide as Menu } from 'react-burger-menu'

import headerMenus from '../../public/static/jsons/header'
import { MenuCollapse } from './MenuCollapse'

const BurgerMenuComponent = (props) => {
	const { language, router } = props
	const currentPath = router?.router?.pathname || ''

	const labels = headerMenus[language]

	return (
		<BurgerMenuContainer>
			<Menu
				pageWrapId={props.pageWrapId}
				outerContainerId={props.outerContainerId}
				customBurgerIcon={ <img src={`${CDN_ENDPOINT}/static/images/icons/burger-button.svg`} /> }
				width={260}>
				<div className="side-menus">
					<div className="menu-header">
						<Link
							href={{
								pathname: '/',
								query: { language },
							}}
							as={`/${language}`}
							shallow={true}
							passHref>
							<a className="header-logo">
								<img
									src={`${CDN_ENDPOINT}/static/images/${labels?.general?.logo}`}
									alt={labels?.general?.name}
								/>
							</a>
						</Link>
					</div>

					<ul>
						{labels?.topNav?.map((item) => (
							<MenuCollapse
								language={language}
								currentPath={currentPath}
								menu={item}
								key={item?.title}
							/>
						))}
					</ul>
				</div>
			</Menu>
		</BurgerMenuContainer>
	)
}

const BurgerMenu = withRouterContext(BurgerMenuComponent)
export { BurgerMenu }
