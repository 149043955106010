import * as React from 'react'
import { CDN_ENDPOINT } from '@env'
import { basicStyle, Row, Col, SelectOption, Select } from '@sejinmind/piip-ui-components/src/v1'
import { HeaderContainer, MainMenus } from './style'
import { Submenu } from './Submenu'
import { Link, ActiveLink, withRouterContext } from '@sejinmind/piip-utils'

import headerMenus from '../../public/static/jsons/header'

const NextActiveLink = withRouterContext(ActiveLink)

class TopNav extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			current: 'home',
			headerLanguage: props.language,
		}
	}

	onChangeLanguage = (value) => {
		const { router } = this.props

		const { query, pathname, asPath } = router?.router

		const paths = asPath.split('/')
		paths[1] = value

		const newPath = paths.join('/')

		this.setState({ headerLanguage: value }, () => {
			//FIXME
			// router push 시에 같은 컴포넌트(언어전환) 할때 head 정보가 날라감
			// https://nextjs.org/docs/api-reference/next/head
			// unmount시 head정보가 clear 되는듯함
			// 추후 react-intl 등 라이브러리로 언어정보를 상태값으로 치환 필요
			// 임시 방편으로 언어전환 액션시 얉은 이동으로 url만 바꿔준 후 reload 시킴
			// 구독 등 다른 페이지에도 있으니 주의
			// 뒤로 가기시에는 아직 정보가 날라감
			router.push({ pathname, query: { ...query, language: value } }, newPath, {
				shallow: true,
			})

			router.events.on('routeChangeComplete', () => {
				router.reload()
			})
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { language } = this.props
		const { headerLanguage } = this.state
		if (headerLanguage !== language) {
			this.setState({ headerLanguage: language })
		}
	}

	render() {
		const { rowStyle, colStyle, gutter } = basicStyle

		const { language } = this.props
		const { headerLanguage } = this.state

		const { general, topNav, languageSelector } = headerMenus[language]

		return (
			<HeaderContainer>
				<div>
					<Link
						href={{
							pathname: '/',
							query: { language },
						}}
						as={`/${language}`}
						shallow={true}
						passHref>
						<a className="logo">
							<img
								src={`${CDN_ENDPOINT}/static/images/${general?.logo}`}
								alt={general?.name}
							/>
						</a>
					</Link>

					<div className="nav-left">
						<MainMenus>
							{topNav.map((item) => {
								return (
									<li key={item.title}>
										<NextActiveLink
											href={{ pathname: item.link, query: { language } }}
											as={`/${language}${item.link}`}
											activePath={item.activePath}
											text={item.title}
										/>
										<Submenu language={language} config={item.subNav} />
									</li>
								)
							})}
						</MainMenus>
					</div>
				</div>

				<div className="nav-right">
					<Select
						defaultValue={language}
						value={headerLanguage}
						onChange={this.onChangeLanguage}>
						{languageSelector?.map((item) => (
							<SelectOption value={item.value} key={item.value}>
								{item.label}
							</SelectOption>
						))}
					</Select>
				</div>
			</HeaderContainer>
		)
	}
}

const Header = withRouterContext(TopNav)
export { Header }
