import * as React from 'react'
import { CDN_ENDPOINT } from '@env'
import { basicStyle, Row, Col, Divider, Button } from '@sejinmind/piip-ui-components/src/v1'
import { FooterContainer } from './style'
import { LogoLinkedin16, LogoYoutube16, LogoFacebook16 } from '@carbon/icons-react'

import footerJson from '../../public/static/jsons/footer.json'

import { Link } from '@sejinmind/piip-utils'

class Footer extends React.Component {
	render() {
		const { rowStyle, colStyle, gutter } = basicStyle

		const { language } = this.props

		const {
			general,
			contact,
			newsletter,
			snsList,
			awards,
			notableFirm,
			menu,
			copyright,
		} = footerJson[language]

		return (
			<FooterContainer>
				<Row style={rowStyle} gutter={gutter} type="flex">
					<Col
						xl={{ span: 8, offset: 2 }}
						lg={{ span: 8, offset: 2 }}
						md={24}
						sm={24}
						xs={24}>
						<Link
							href={{
								pathname: '/',
								query: { language },
							}}
							as={`/${language}`}
							shallow={true}
							passHref>
							<a className="logo">
								<img
									src={`${CDN_ENDPOINT}/static/images/${general?.logo}`}
									alt={general?.name}
								/>
							</a>
						</Link>
					</Col>
					<Col xl={6} lg={6} md={12} sm={12} xs={16} className="footer-content-wrapper">
						<p className="title">{contact?.title}</p>
						<p
							className="content"
							dangerouslySetInnerHTML={{
								__html: contact?.address,
							}}
						/>
						<p
							className="content"
							dangerouslySetInnerHTML={{
								__html: contact?.email,
							}}
						/>
						<p
							className="content"
							dangerouslySetInnerHTML={{
								__html: contact?.phone,
							}}
						/>
					</Col>
					<Col xl={6} lg={6} md={8} sm={8} xs={16} className="footer-content-wrapper">
						<p className="title">{newsletter?.title}</p>
						<Link
							href={{
								pathname: newsletter?.newsletterBtnLink,
								query: {
									language,
								},
							}}
							as={`/${language}${newsletter?.newsletterBtnLink}`}
							shallow={true}>
							<a target="_blank">
								<Button block>{newsletter?.newsletterBtn}</Button>
							</a>
						</Link>
						<a href={snsList?.linkedIn?.link} target="_blank" className="content">
							<LogoLinkedin16 className="icon" />
							<span>{snsList?.linkedIn?.title}</span>
						</a>
						<a href={snsList?.youtube?.link} target="_blank" className="content">
							<LogoYoutube16 className="icon" />
							<span>{snsList?.youtube?.title}</span>
						</a>
						<a href={snsList?.facebook?.link} target="_blank" className="content">
							<LogoFacebook16 className="icon" />
							<span>{snsList?.facebook?.title}</span>
						</a>
					</Col>
				</Row>
				<Row style={rowStyle} gutter={gutter} type="flex">
					<Col
						xl={{ span: 20, offset: 2 }}
						lg={{ span: 20, offset: 2 }}
						md={24}
						sm={24}
						xs={24}
						className="footer-award-wrapper">
						{awards?.map((award, index) => (
							<a className="award-link" href={award?.link} target="_blank" key={`award-${index}`}>
								<img
									className="award-img"
									src={`${CDN_ENDPOINT}/static/images${award?.image}`}
									alt={award?.alt}
									key={`award-${index}`}
								/>
							</a>
						))}
					</Col>
				</Row>
				<Row style={rowStyle} gutter={gutter} type="flex">
					<Col
						xl={{ span: 20, offset: 2 }}
						lg={{ span: 20, offset: 2 }}
						md={24}
						sm={24}
						xs={24}>
						<Divider />
					</Col>
				</Row>
				<Row style={rowStyle} gutter={gutter} type="flex">
					<Col
						xl={{ span: 8, offset: 2 }}
						lg={{ span: 8, offset: 2 }}
						md={24}
						sm={24}
						xs={24}
						className="menu">
						{menu.map((item, index) => (
							<Link
								href={{
									pathname: item?.link,
									query: { language },
								}}
								as={`/${language}${item?.link}`}
								shallow={true}
								key={index}>
								<a
									target="_blank"
									dangerouslySetInnerHTML={{ __html: item.label }}
								/>
							</Link>
						))}
					</Col>
					<Col xl={12} lg={12} md={24} sm={24} xs={24} className="cc-right">
						{copyright}
					</Col>
				</Row>
			</FooterContainer>
		)
	}
}

export { Footer }
