import * as React from 'react'
import { SubmenuWrapper } from './style'
import { Row, Col, Button } from '@sejinmind/piip-ui-components/src/v1'
import { Link } from '@sejinmind/piip-utils'

import PropTypes from 'prop-types'

const MenuBlock = (props) => {
	const { language, items, isProfessionals } = props

	if (!isProfessionals) {
		return items.map((item) => (
			<Col xl={8} lg={24} md={24} className="menu-block" key={item?.title}>
				<Link
					href={{
						pathname: item?.link?.pathname,
						query: { language, ...item?.link?.query },
					}}
					as={`/${language}${item?.link?.as}`}
					shallow={true}
					key={item.title}>
					<a className="block-content" target={item?.link?.isBlank ? '_blank' : '_self'}>
						<p
							className="block-title"
							dangerouslySetInnerHTML={{ __html: item?.title }}
						/>
						{item?.description && (
							<p className="block-description">{item?.description}</p>
						)}
					</a>
				</Link>
			</Col>
		))
	} else {
		return (
			<Col span={24} className="menu-block" key={items[0]?.title}>
				<Link
					href={{
						pathname: items[0]?.link?.pathname,
						query: { language, ...items[0]?.link?.query },
					}}
					as={`/${language}${items[0]?.link?.as}`}
					shallow={true}>
					<a className="block-content">
						<p
							className="block-title"
							dangerouslySetInnerHTML={{ __html: items[0]?.title }}
						/>
						{items[0]?.description && (
							<p className="block-description">{items[0]?.description}</p>
						)}
					</a>
				</Link>
			</Col>
		)
	}
}

export const Submenu = (props) => {
	const { language, config } = props

	const isProfessionals = config?.menuItem?.menuItemBtnLink === '/professionals'

	const groupedMenus = config?.subMenuItems?.reduce((result, item) => {
		item.visible !== 'mobile' && (result[item.type] = result[item.type] || []).push(item)

		return result
	}, {})

	return (
		<SubmenuWrapper>
			<div className="submenu">
				<div className="flex-holder">
					<div className="submenu-title-section">
						<p className="title">{config?.menuItem?.title}</p>
						{config?.menuItem?.description && (
							<p
								className="description"
								dangerouslySetInnerHTML={{ __html: config?.menuItem?.description }}
							/>
						)}

						{config?.menuItem?.menuItemBtnLink && (
							<Link
								href={{
									pathname: `${config?.menuItem?.menuItemBtnLink}`,
									query: { language },
								}}
								as={`/${language}${config?.menuItem?.menuItemBtnLink}`}
								shallow={true}>
								<Button>{config?.menuItem?.menuItemBtn}</Button>
							</Link>
						)}
					</div>
					<div className="submenu-list-section">
						<Row type="flex">
							{Object.keys(groupedMenus).map((key) => (
								<Col
									xl={24}
									lg={!isProfessionals ? 12 : 24}
									md={12}
									className="submenu-group"
									key={key}>
									{key !== 'undefined' && (
										<p className="submenu-group-title">{key}</p>
									)}
									<Row type="flex" className="group-menu">
										<MenuBlock
											language={language}
											items={groupedMenus[key]}
											isProfessionals={isProfessionals}
											key={key}
										/>
									</Row>
								</Col>
							))}
						</Row>
					</div>
				</div>
			</div>
		</SubmenuWrapper>
	)
}

// Submenu.propTypes = {
// 	language: PropTypes.string.isRequired,
// 	config: PropTypes.shape({
// 		menuItem: PropTypes.shape({
// 			title: PropTypes.string.isRequired,
// 			description: PropTypes.string.isRequired,
// 			menuItemBtn: PropTypes.string.isRequired,
// 			menuItemBtnLink: PropTypes.string.isRequired,
// 		}),
// 		subMenuItems: PropTypes.arrayOf(
// 			PropTypes.shape({
// 				title: PropTypes.string.isRequired,
// 				description: PropTypes.element,
// 				link: PropTypes.string,
// 			}),
// 		),
// 	}).isRequired,
// }
