import styled from 'styled-components'
import { palette } from 'styled-tools'
import { antMedia } from '@sejinmind/piip-ui-components/src/v1'

const BurgerMenuContainer = styled.div`
	background-color: #ffffff;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
		
	.side-menus {
		outline: none;
	}

	a {
		color: #4e4a46;
		text-decoration: none;

		&:hover,
		&:focus {
			color: #c94e50;
		}
	}

	main {
		height: 100%;
		padding: 3em 2em;
		text-align: center;
		background: #b4bad2;
		overflow: scroll;
	}

	h1 {
		font-weight: 800;
		font-size: 3.75em;
	}

	.description {
		max-width: 20em;
		margin: 1.2em auto 1em;
		line-height: 1.3em;
		font-weight: 400;
	}

	.demo-buttons {
		font-size: 1em;
		max-width: 1200px;
		margin: 2em auto 3em;
		padding: 0 10em;

		a {
			display: inline-block;
			margin: 0.75em;
			padding: 1.35em 1.1em;
			width: 15em;
			background: #fffce1;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: 800;
			border-top-left-radius: 20px 50px;
			border-top-right-radius: 20px 50px;
			border-bottom-right-radius: 20px 50px;
			border-bottom-left-radius: 20px 50px;
			cursor: pointer;

			&.current-demo {
				background: #c94e50;
				color: #fffce1;
			}
		}
	}

	.side-button {
		display: inline-block;
		width: 5em;
		height: 2.5em;
		line-height: 2.5em;
		cursor: pointer;
		background: #fffce1;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 0.8em;
		font-weight: 800;

		&.left {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		&.right {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		&.active {
			background: #F2F3F4;
			color: #333941;
		}
	}

	@media screen and (max-width: 40em) {
		main {
			font-size: 80%;
		}

		h1 {
			padding-top: 1em;
			font-size: 2.5em;
		}

		.demo-buttons {
			max-width: 900px;
			padding: 0 2em;
		}
	}

	//
	// Burger menu custom styles
	//
	.bm-overlay {
		z-index: 2147483646 !important;
		background: rgba(4, 4, 4, 0.15) !important;
	}
	.bm-menu-wrap {
		z-index: 2147483647 !important;
	}
	.bm-burger-button {
		z-index: 2147483645 !important;
		position: fixed;
		width: 24px;
		height: 24px;
		//right: 10px;
		left: 16px;
		top: 16px;

		${antMedia.greaterThan('lg')`
	   		display: none;
  		`};
	}

	.right .bm-burger-button {
		left: initial;
		right: 36px;
	}

	.bm-burger-bars {
		background: #373a47;
	}

	.bm-morph-shape {
		fill: #373a47;
	}

	.bm-menu {
		background: #fff;
	}
	
	.bm-item {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 148%;
		
		ul {
			list-style: none;
			
			padding-inline-start: 0px;
			
			li {
				width: 100%;
			}
			
			.ant-collapse {
				border: none;
				
				.ant-collapse-item {
					.ant-collapse-header {
						padding-top: 16px;
						padding-bottom: 16px;
						padding-left: 32px;
						
						background: #ffffff;
						
						.ant-collapse-header-text {
							color: #667283;
						}
						
						
						
						p {
							margin-bottom: 0px;
							 
							color: #667283;
						}
						
						&:hover,
						&:focus {
							color: #333941;
							
							p {
								color: #333941;
							}
						}
					}
					
					.ant-collapse-content {
						.ant-collapse-content-box {
							padding: 0px;
							p {
								margin-bottom: 0px;
								padding: 16px;
								
								cursor: pointer;
								&:hover,
								&:focus {
									background: #F2F3F4;
									
									text-decoration: underline;
									color: #333941;
								}
							}
						}
					}
				}
				
				.ant-collapse-item-active {
					.ant-collapse-header {
						color: #333941;
					}
				}
			}
		}		
	}

	//
	// Mixins
	//
	.menu-1 {
		.bm-cross {
			background: #bdc3c7;
		}

		.bm-menu {
			padding: 2.5em 1.5em 0;
			font-size: 1.15em;
		}
	}

	.menu-2 {
		.bm-cross {
			background: #999;
		}

		.bm-menu {
			padding: 3.4em 1em 0;
		}

		a {
			padding: 1em;
		}

		i {
			font-size: 1.7em;
			vertical-align: middle;
			color: #282a35;
		}
	}

	.menu-3 {
		.bm-cross {
			background: #888;
		}

		.bm-menu {
			padding: 2em 1em;
			font-size: 1.15em;
		}

		i {
			opacity: 0.5;
		}

		span {
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 0.75em;
		}
	}

	.menu-4 {
		.bm-cross {
			background: #888;
		}

		h2 {
			margin: 0 auto;
			padding: 2em 1em;
			color: rgba(0, 0, 0, 0.4);
			box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

			i {
				margin-left: 0.2em;
			}

			span {
				font-size: 1.6em;
				font-weight: 700;
			}
		}

		a {
			padding: 1em;
			text-transform: uppercase;
			transition: background 0.3s, box-shadow 0.3s;
			box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

			span {
				letter-spacing: 1px;
				font-weight: 400;
			}

			&:hover,
			&:focus {
				background: rgba(0, 0, 0, 0.2);
				box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
				color: #b8b7ad;
			}
		}
	}

	#slide,
	#stack,
	#reveal {
		.menu-1;
	}

	#bubble,
	#push {
		.menu-2;
	}

	#elastic {
		.menu-3;
	}

	#scaleDown,
	#scaleRotate,
	#pushRotate,
	#fallDown {
		.menu-4;
	}
	
	.menu-header {
		display: flex;
		align-items: center;

		height: 56px;
		padding-inline-start: 32px;
		border-bottom: 1px solid #D2D6DC;
		
		.header-logo {
			font-size: 16px;
			font-weight: bold;
			float: left;
			cursor: pointer;
			transition: all 0.3s ease;

			opacity: 0.9;
			&:hover {
				opacity: 1;
			}
			
			img {
				width: 120px;
			}

			color: #2c2c2c;
		}
	}
`

export { BurgerMenuContainer }
