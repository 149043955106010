import styled from 'styled-components'
import { palette } from 'styled-tools'
import { Header as antHeader, media, antMedia } from '@sejinmind/piip-ui-components/src/v1'

export const HeaderContainer = styled(antHeader)`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	width: 100%;
	height: 72px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 0px 32px;

	background-color: #ffffff;
	border: 0px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
	
	${antMedia.lessThan('lg')`
   		padding: 0;
   		padding-left: 56px;
   		
   		height: 56px;
  	`};

	.logo {
		display: block;

		// ${antMedia.greaterThan('lg')`
	    //     display: block;
	    // `}

		float: left;

		opacity: 0.9;
		&:hover {
			opacity: 1;
		}

		img {
			width: 120px;

			margin-right: 48px;

			cursor: pointer;

			transition: all 0.3s ease;

			opacity: 1;
			&:hover {
				opacity: 0.8;
			}
		}
	}

	.nav-left {
		display: none;
		float: left;

		${antMedia.greaterThan('lg')`
			display: inherit; 
		`};
	}
	
	.nav-right {
		.ant-select {
        	width: 80px;
        	
            .ant-select-selector {
                padding: 0px;     
                
                border: 0px;
    
                background: none;
            }
            
            .ant-select-selection-search {
                background: none;
            }
            
            .ant-select-selection-item {
            	padding-right: 32px;
            	
            	text-align: right;
            	
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                                
               	color: #333941;
            }
            
            .ant-select-arrow {
                color: #333941;
            }
        }
        
        .ant-select-focused {
			.ant-select-selector {
				box-shadow: none !important;
			}
		}
	
		.ant-select-open {
			.ant-select-selection {
				box-shadow: none !important;
			}
		}
	}
`

export const MainMenus = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		float: left;
		padding: 0 16px;

		a {
			display: inline-block;

			padding: 21px 0px;

			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 148%;

			color: #667283;

			box-sizing: border-box;

			&:hover,
			&:focus {
				color: #333941;
				text-decoration: none;
				border-bottom: 2px solid #305587;
			}

			&.active {
				color: #333941;
				text-decoration: none;

				border-bottom: 2px solid #305587;
			}
		}

		&:hover .submenu,
		&:focus .submenu {
			visibility: visible;
			opacity: 1;
			transition: visibility 0.3s;
		}
	}
`

export const SubmenuWrapper = styled.div`
	.submenu {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0.3s;

		z-index: 100;

		background: #ffffff;

		position: absolute;
		left: 0;
		top: 72px;

		width: 100%;
		min-height: 310px;

		border: 0px;
		box-shadow: 0px 1px 1.5px rgba(0, 0, 0, 0.12);

		.flex-holder {
			display: flex;
		}

		.submenu-title-section {
			display: inline-block;

			flex: 1;

			max-width: 512px;
			min-height: 310px;

			${antMedia.lessThan('1376px')`
			   max-width: 480px;
			`}

			${antMedia.lessThan('xl')`
			   max-width: 448px;   
			`}

			padding: 48px 96px;

			background: #305587;
			border: 0px;

			color: #ffffff;
			font-style: normal;
			font-weight: 100;

			.title {
				margin-bottom: 16px;

				font-size: 36px;
				line-height: 148%;
			}

			.description {
				margin-bottom: 48px;

				font-size: 16px;
				line-height: 148%;
			}

			.ant-btn {
				min-width: 200px;
				height: 48px;

				border: 2px solid #f8f8f8;
				background: none;

				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 148%;
				color: #ffffff;

				&:hover {
					background: #f8f8f8;
					color: #333941;

					border: none;
				}
			}
		}

		.submenu-list-section {
			display: inline-block;

			flex: 1;

			min-height: 310px;

			padding: 48px;

			${antMedia.lessThan('xl')`
				padding: 48px 32px;
			`}

			border: 0px;

			.submenu-group {
				margin-bottom: 24px;

				${antMedia.lessThan('xl')`
					margin-bottom: 0px;
				`}

				max-width: 840px;

				&:last-child {
					margin-bottom: 0px;
				}

				.submenu-group-title {
					margin: 0px;
					padding: 0px 24px;

					${antMedia.lessThan('xl')`
						padding: 0px 12px;
					`}

					font-style: normal;
					font-weight: normal;
					font-size: 13px;
					line-height: 148%;

					text-transform: uppercase;

					color: #85909f;
				}

				.menu-block {
					color: #333941;

					cursor: pointer;

					&:hover {
						background-color: #f8f8f8;

						.block-title {
							color: #305587;
						}
					}

					.block-content {
						padding: 12px 24px;

						${antMedia.lessThan('xl')`
						   padding: 16px 12px;
						`}

						&:hover {
							border-bottom: none;
							text-decoration: none;
						}

						.block-title {
							margin-bottom: 4px;

							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 148%;
						}

						.block-description {
							margin: 0px;

							max-height: 64px;

							overflow: hidden;
							text-overflow: ellipsis;

							font-style: normal;
							font-weight: 100;
							font-size: 14px;
							line-height: 148%;

							${antMedia.lessThan('1376px')`
							  display: none;
							`}
						}
					}
				}
			}
		}
	}
`
