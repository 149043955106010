import * as React from 'react'
import { Header } from '../components/Header/Header'
import { Footer } from '../components/Footer/Footer'
import { BurgerMenu } from '../components/BurgerMenu/BurgerMenu'
import { Layout, Content } from '@sejinmind/piip-ui-components/src/v1'

class ApplicationLayout extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { children, backgroundColor, language = 'en' } = this.props

		const contentStyle = { paddingTop: 0, paddingBottom: 0 }

		if (backgroundColor !== undefined || backgroundColor !== null) {
			Object.assign(contentStyle, { backgroundColor })
		}

		return (
			<div id="outer-container">
				<BurgerMenu language={language} pageWrapId="page-wrap" outerContainerId="outer-container" />

				<Layout className="layout">
					<Header language={language} />
					<Content style={contentStyle}>{children}</Content>
					<Footer language={language} />
				</Layout>
			</div>
		)
	}
}

export { ApplicationLayout }
