import * as React from 'react'
import { Collapse, CollapsePanel } from '@sejinmind/piip-ui-components/src/v1'
import { Link } from '@sejinmind/piip-utils'

export const MenuCollapse = (props) => {
	const { language, menu } = props

	const isTheFirm = menu?.link === '/about-us'

	return (
		<li key={menu?.title}>
			<Collapse>
				<CollapsePanel
					header={
						!isTheFirm ? (
							<Link
								href={{
									pathname: `${menu.link}`,
									query: { language },
								}}
								as={`/${language}${menu.link}`}
								shallow={true}>
								<p>{menu?.title}</p>
							</Link>
						) : (
							menu.title
						)
					}
					showArrow={isTheFirm}>
					{isTheFirm &&
						menu?.subNav?.subMenuItems?.map((subItem, index) => (
							<Link
								href={{
									pathname: `/${language}${subItem?.link?.pathname}`,
									query: { language, ...subItem?.link?.query },
								}}
								as={`/${language}${subItem?.link?.as}`}
								shallow={true}
								key={index}>
								<p>{subItem?.title}</p>
							</Link>
						))}
				</CollapsePanel>
			</Collapse>
		</li>
	)
}
