import styled from 'styled-components'
import { palette } from 'styled-tools'
import { Footer as antFooter, media, antMedia } from '@sejinmind/piip-ui-components/src/v1'
import * as React from 'react'

export const FooterContainer = styled(antFooter)`
	position: relative;

	width: 100%;

	padding-top: 96px;
	padding-bottom: 32px;

	padding-right: 0px;
	padding-left: 0px;

	${antMedia.lessThan('lg')`
		padding-top: 64px;
	`}

	background-color: #F3F4F6;

	.logo {
		img {
			${antMedia.lessThan('lg')`
				margin-bottom: 64px;
			`}

			cursor: pointer;

			transition: all 0.3s ease;

			opacity: 1;
			&:hover {
				opacity: 0.8;
			}
		}
	}

	.footer-content-wrapper {
		margin-bottom: 96px;
		
		${antMedia.lessThan('sm')`
			margin-bottom: 48px;
			&:last-child {
				margin-bottom: 96px;
			}
		`}

		.title {
			margin-bottom: 16px;

			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 148%;

			text-transform: uppercase;

			color: #333941;
		}

		.content {
			margin-bottom: 16px;
			&:last-child {
				margin-bottom: 0px;
			}

			display: flex;
			align-items: center;

			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 178%;

			color: #444c57;

			.icon {
				margin-right: 8px;

				color: #444c57;
			}
		}

		a {
			&:hover {
				text-decoration: underline;
			}
		}

		.ant-btn {
			margin-bottom: 32px;

			background: #305587;
			border: none;

			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 148%;

			text-align: center;

			color: #ffffff;

			&:hover {
				background: #26436a;
			}
		}
	}

	.footer-award-wrapper {
		display: flex;
		flex-wrap: wrap;
		row-gap: 8px;
		
		.award-link {
			margin-right: 8px;
			&:last-child {
				margin-right: 0px;
			}

			.award-img {
				height: 56px;
				width: auto;
				
				image-rendering: -webkit-optimize-contrast;
			}
		}
	}

	.ant-divider {
		margin-top: 12px !important;
		margin-bottom: 24px !important;
		${antMedia.lessThan('md')`
			margin-bottom: 32px !important;
		`}

		border-top: 1px solid #D3D5D8 !important;
	}

	.menu {
		${antMedia.lessThan('md')`
			margin-bottom: 16px;
		`}

		a {
			margin-right: 24px;
			&:last-child {
				margin-right: 0px;
			}

			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 148%;

			color: #333941;

			&:hover {
				text-decoration: underline;
			}
		}

		.bold {
			font-weight: bold;
		}
	}

	.cc-right {
		font-style: normal;
		font-weight: 300;
		font-size: 13px;
		line-height: 148%;

		text-align: right;
		${antMedia.lessThan('md')`
			text-align: left;
		`}

		color: #444c57;
	}
`
